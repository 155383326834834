import * as React from "react"
import Layout from "../components/Layout"

class Collection extends React.Component {
  render() {
    
    return (
      <Layout>
        <div>
          collection
        </div>
      </Layout>
    )
  }
}

export default Collection;
